import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"


import styled from "styled-components"

const Container = styled.div`
  display: flex;
  grid-template-columns: 100px;
  background: #101111;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  height : 100vh;
`

const Box= styled.div`
  background: #373142;
  color: #FFF;
  width: 10px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  margin: 20px;
  //border: solid 1px red;
  &:nth-child(4) {
    height: 150px;
    flex-grow: 1;
  }
  &:nth-child(2) {
    height: 250px;
    flex-grow: 2;
  }
  &:nth-child(3) {
    height: 250px;
    flex-basis: 100px;
    flex-grow: 2;
  }
`

const IndexPage = ({location}) => (
  <Layout location={location}>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Container>
    <Box>1</Box>
    <Box>2</Box>
    <Box>3</Box>
    <Box>4</Box>
    <Box>5</Box>
    <Box>6</Box>
    <Box>7</Box>
    <Box>8</Box>
    </Container>
    <div style={{ maxWidth: `3000px`, marginBottom: `1.45rem` }}>
    </div>
  </Layout>
)

export default IndexPage
